<template>
  <div>
    <BaseTable
      class="mt-5 table-shadow"
      :headers="headers"
      :items="infoPermissoes"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="descricao_modulo"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Consulta</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="1292"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FilterPermissao @selectedFilters="fetchPermissoes($event)" />
        </v-toolbar>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn icon v-can-access="1293" @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <!-- <v-btn
          v-can-access="1422"
          icon
          @click="vinculaPermissao(item)"
          class="ml-2"
          color="primary"
          dark
        >
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>-->

        <!--  <v-btn
          v-can-access="1295"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom
          :name="'edit'"
          v-can-access="1293"
          @click="editItem(item)"
        />
        <IconBottom
          :name="'bond'"
          v-can-access="1422"
          @click="vinculaPermissao(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="1295"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadPermissoes
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editPermissao="permissao"
      @close="refetch"
    />
    <DraggablePermissao
      :dialog="dialogVinculo"
      :dadosPermissao="permissao"
      @close="dialogVinculo = false"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterPermissao from "./FilterPermissao";
import DraggablePermissao from "./DraggablePermissao.vue";
import IconBottom from "@/components/shared/bottons/IconBottom";
import sistema from "@/services/http/sistemaService";
import ModalCadPermissoes from "@/components/sistema/permissoes/ModalCadPermissoes";

export default {
  name: "Permissoes",

  components: {
    BaseTable,
    RegisterBottom,
    DraggablePermissao,
    FilterPermissao,
    ModalCadPermissoes,
    IconBottom
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogVinculo: false,
      labelBtn: "Salvar",
      search: "",
      infoPermissoes: [],
      permissao: {},
      headers: [
        { text: "ID", value: "id_permissao" },
        { text: "Permissão", value: "descricao_permissao" },
        { text: "Módulo", value: "descricao_modulo" },
        { text: "Menu", value: "descricao_menu" },
        { text: "Router", value: "nome" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  methods: {
    async fetchPermissoes(filter) {
      this.loading = true;
      let { data } = await sistema()
        .permissoes()
        .show({
          per_page: -1,
          ...filter
        });
      this.infoPermissoes = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.permissao = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    vinculaPermissao(item) {
      this.permissao = Object.assign({}, item);
      this.dialogVinculo = true;
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar permissão",
          `Deseja deletar a permissão ${item.descricao_permissao}?`
        );
        sistema()
          .permissoes(item.id_permissao)
          .delete({}, { notification: true, message: "Pemissão deletada" });
      } catch (error) {
        console.log(error);
      }
      await this.fetchPermissoes();
    },

    async refetch() {
      this.dialog = false;
      await this.fetchPermissoes();
    }
  },

  mounted() {
    this.fetchPermissoes();
  }
};
</script>

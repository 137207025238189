<template>
  <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
    <v-card>
      <v-card-title
        class="flex-column align-start
"
      >
        <span class="headline">
          Vincular Papel a rota:
          {{ dadosPermissao.nome }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Papeis não
              Vinculados
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodos()"
              :disabled="naoVinculados.length == 0"
            >
              Adicionar todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area exclude-companies"
              :list="naoVinculados"
              group="equipes"
              @change="moveAdded()"
            >
              <div
                class="v-list-item__content list"
                v-for="papel in naoVinculados"
                :key="papel.id"
              >
                <div class="list-equipe-items">
                  <div>
                    <div class="d-flex align-center">
                      <strong>
                        {{ papel.nome }}
                      </strong>
                    </div>

                    <div class="d-flex align-center">
                      {{ papel.descricao }}
                    </div>
                  </div>
                  <div class="mr-4">
                    <v-btn icon @click="add(papel)">
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Papeis
              Vinculados
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodos()"
              :disabled="vinculados.length == 0"
            >
              Remover todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area include-companies"
              :list="vinculados"
              group="equipes"
              @change="moveRemove()"
            >
              <div
                class="v-list-item__content"
                v-for="papelPermissao in vinculados"
                :key="papelPermissao.id_papeis_permissoes"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn class="mr-4" icon @click="remover(papelPermissao)">
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <div class="d-flex align-center">
                      <strong>
                        {{ papelPermissao.papel }}
                      </strong>
                    </div>
                    <div class="d-flex align-center">
                      {{ papelPermissao.descricao_papel }}
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import sistema from "@/services/http/sistemaService";

export default {
  name: "DraggablePermissao",

  components: {
    draggable
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    dadosPermissao: {
      type: Object
    }
  },

  data() {
    return {
      form: {
        id_band: "",
        id_empresa: ""
      },
      naoVinculados: [],
      vinculados: []
    };
  },

  watch: {
    dadosPermissao(value) {
      this.vinculo();
      return value;
    }
  },

  methods: {
    async vinculo() {
      let papeis = await this.fetchPapeis();
      this.vinculados = await this.fetchPapeisPermissoes();

      let idVinculados = this.vinculados.map(item => parseInt(item.id_papeis));

      this.naoVinculados = papeis.filter(
        item => !idVinculados.includes(item.id)
      );

      this.naoVinculados.sort((a, b) => (a.id > b.id ? 1 : -1));
    },

    async fetchPapeisPermissoes() {
      let { data } = await sistema()
        .papeisPermissoes()
        .show({
          per_page: -1,
          id_permissao: this.dadosPermissao.id_permissao
        });
      return data.data.sort((a, b) => (a.id_papeis > b.id_papeis ? 1 : -1));
    },

    async fetchPapeis() {
      let { data } = await sistema()
        .papeis()
        .show({
          per_page: -1
        });
      return data.data;
    },

    async addTodos() {
      try {
        let allPost = this.naoVinculados.map(item => {
          const form = {
            id_papeis: item.id,
            id_permissoes: this.dadosPermissao.id_permissao
          };

          return sistema()
            .papeisPermissoes()
            .store(form, {
              notification: true,
              message: "Colaborador vinculado com sucesso!"
            });
        });

        await Promise.all(allPost);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: `Erro ao adicionar papel`
        });
      } finally {
        this.vinculo();
      }
    },
    async removerTodos() {
      try {
        let allDelete = this.vinculados.map(item => {
          return sistema()
            .papeisPermissoes(item.id_papeis_permissoes)
            .delete(
              {},
              { notification: true, message: "Empresa removida com sucesso!" }
            );
        });
        await Promise.all(allDelete);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover empresa`
        });
      } finally {
        this.vinculo();
      }
    },

    async add(item) {
      try {
        const form = {
          id_papeis: item.id,
          id_permissoes: this.dadosPermissao.id_permissao
        };
        await sistema()
          .papeisPermissoes()
          .store(form, {
            notification: true,
            message: "Empresa vinculada com sucesso!"
          });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar  ${this.dadosPermissao.nome}`
        });
      } finally {
        this.vinculo();
      }
    },

    async remover(item) {
      try {
        await sistema()
          .papeisPermissoes(item.id_papeis_permissoes)
          .delete(
            {},
            { notification: true, message: "Empresa removida com sucesso!" }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover Empresa`
        });
      } finally {
        this.vinculo();
      }
    },

    moveAdded(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.add(empresa);
      }
    },

    moveRemove(evt) {
      let empresa = {};
      const { removed } = evt;

      if (removed) {
        empresa = removed.element;
        this.remover(empresa);
      }
    },

    close() {
      this.$emit("close");
    },

    default() {
      this.form.id_band = "";
      this.form.id_empresa = "";
      this.naoVinculados = [];
      this.vinculados = [];
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
